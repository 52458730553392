@import 'variables';
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/bootstrap.scss";


body {
  background: #333339;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  min-height: 100vh;
  display:flex; 
  flex-direction:column;
}

#root {
  min-height: 100vh;
  display:flex; 
  flex-direction:column;
}

// REMOVE YELLOW INPUT BACKGROUND ON AUTOFILL IN CHROME 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: $white !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}

nav {
  background: $orange;

  .nav-item {
    font-weight: 600;

    .nav-link.active, .nav-link:active {
      color: #000;
    }
  }
}

.color-orange {
  color: $orange;
}

.color-black {
  color: #000;
}

.container {
  .row {
    width: 100%;
  }
}

.card-body {
  background: #222222;
  color: $orange;
  height: 100px;
}

.box {
  background: #222222;
  padding: 30px;

  .about-section {
    h5 {
      text-decoration: underline;
    }
    h5:not(:first-child) {
      margin-top: 1.5em; 
    }
  }

  .box-items-container {
    text-align: center;

    a {
      color: $orange;

      &:hover {
        color: orangered;
      }
    }

    & > div:not(:first-child) {
      margin-top: 20px;
    }

    .error-header {
      color: red;
      font-family: "Comic Sans MS", "Comic Sans", cursive;
    }

    .badge {
      h5 {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .queue-notice {
      font-family: "Comic Sans MS", "Comic Sans", cursive;
      color: $warning
    }
  }
}

textarea {
  min-height: 250px;
  max-height: 250px;
  border: none;
  //letter-spacing: 0.05em;
  padding: 20px 30px;
  font-size: 12px !important;
  font-family: 'Courier New', Courier, monospace;
}

.blue-team, .red-team {
  font-weight: bold;
  font-size: 20px;
}

.blue-team {
  color: $blue;
}

.red-team {
  color: $red;
}

@include media-breakpoint-down(sm) {
  .box {
    padding: 30px 10px;

    h3 {
      font-size: 20px;
    }
  }

  .blue-team, .red-team {
    font-size: 16px;
  }
}

.button-predict {
  height: 38px;
}

@include media-breakpoint-down(xs) {
  .button-predict {
    display: block;
    width: 100%;
  }

  .box {
    .about-section {
      h5 {
        font-size: 18px;
      }

      p, ul {
        font-size: 14px;
      }

      ul {
        padding-left: 20px;
      }
    }
  }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
}

.players-table {
  color: $white;
  td {
    padding-top: 6px;
    padding-bottom: 6px; 
  }

  @include media-breakpoint-down(xs) {
    td {
      font-size: 12px;

      .name {
        display: block;
      }
    }
  }

  .champion-icon {
    height: 30px;
  }
}

footer {
  background: #222222;
  //position: absolute;
  //bottom: 0;
  margin-top: auto; 
  width: 100%;
  min-height: 50px;
  color: #fff;
  font-size: 12px;

  .row {
    min-height: 50px;
  }
}

// IE Fix
html body .app.flex-row.align-items-center {
  height: 100vh;
}
