// Override Boostrap variables
$theme-colors: (
  primary: rgb(231, 129, 11),
  orange: rgb(231, 129, 11),
  red: #cf171f,
  blue: #20a8d8,
) !default;

$red: #cf171f;
$blue: #20a8d8;
$orange: rgb(231, 129, 11);
$primary: rgb(231, 129, 11);
